import { render, staticRenderFns } from "./completedDetails.vue?vue&type=template&id=05c9119b&scoped=true"
import script from "./completedDetails.vue?vue&type=script&lang=js"
export * from "./completedDetails.vue?vue&type=script&lang=js"
import style0 from "./completedDetails.vue?vue&type=style&index=0&id=05c9119b&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c9119b",
  null
  
)

export default component.exports