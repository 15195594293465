<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <ayl-main title="基本信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="content" v-for="(item, idx) in basicInformation" :key="idx" v-show="idx < (basicInformation.length-1)">
          <span>{{ item.lable }}：</span>
          <span>{{ basicInformationData[item.value] }}</span>
        </div>
        <div class="content">
            <span>位置：</span>
            <span v-text="basicInformationData.postion"></span>
            <a class="mapView" @click="centerDialogVisible = true">查看地图</a>
            <el-dialog
            :visible.sync="centerDialogVisible"
            width="50%"
            @open="newInitAmap"
            center>
            <span>
              <div id="container" style="width: 100%; height: 600px"></div>
            </span>
          </el-dialog>
        </div>     
      </div>
    </ayl-main>
    <ayl-main title="报警信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="content" v-for="(item, idx) in information" :key="idx" v-show="idx < (information.length-1)">
            <span>{{ item.lable }}：</span>
            <span>{{ informationData[item.value] }}</span>
        </div>
        <div class="content">
            <span>附件数：</span>
            <span v-text="informationData.numberOfAttachments"></span>
            <a class="mapView" @click="$router.push('/alarm/annex')">查看附件</a>
        </div>  
      </div>
    </ayl-main>
    <ayl-main title="处理信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="content">
            <span>处理情况：</span>
            <span v-text="processingSituation.code === 0 ? '未处理': '已处理'" :style="{'color': (processingSituation.code === 0 ? '#F5222D':'#666666')}"></span>  
        </div>
        <div class="content">
            <span>处理人：</span>
            <span v-text="processingSituation.processName"></span>  
        </div> 
        <div class="content">
            <span>处理方式：</span>
            <span v-text="processingSituation.processMode "></span>  
        </div> 
        <div class="content">
            <span>处理时间：</span>
            <span v-text="processingSituation.processTime"></span>  
        </div> 
        <div class="content">
            <span>处理内容：</span>
            <span v-text="processingSituation.processTest"></span>  
        </div> 
      </div>
    </ayl-main>
    <div class="tac" style="margin-top: 40px">
      <el-button  style="margin-left: 30px" @click="$router.push('/alarm/alarmDispose')">返回</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        centerDialogVisible: false,
        map: null,
        nav: [
          {name: "报警管理", path: ""},
          {name: "报警处置", path: "/alarm/alarmDispose"},
          {name: "查看详情", path: ""},
        ],
        basicInformation:[
          { 
            lable:'车牌号',
            value:'numberPlate'
          },
          { 
            lable:'车牌颜色',
            value:'color'
          },
          { 
            lable:'所属公司',
            value:'own'
          },
          { 
            lable:'驾驶员姓名',
            value:'driverName'
          },
          { 
            lable:'身份证号',
            value:'identity'
          },
          { 
            lable:'联系方式',
            value:'information'
          },
          { 
            lable:'状态',
            value:'status'
          },
          { 
            lable:'行驶速度',
            value:'drivingSpeed'
          },
          { 
            lable:'行驶里程',
            value:'drivingDistance'
          },
          { 
            lable:'行驶里程',
            value:'drivingDistance'
          },
          { 
            lable:'经度',
            value:'longitude'
          },
          { 
            lable:'纬度',
            value:'latitude'
          },
          { 
            lable:'海拔',
            value:'altitude'
          },
          { 
            lable:'位置',
            value:'postion'
          },
        ],
        basicInformationData: {
          numberPlate: 'A8888',
          color: '蓝色',
          own: '湖南艾依灵科技有限公司',
          driverName: '张三',
          identity: '11111111111',
          information: '1388888888',
          status: 'ACC开；未插卡；定位；正常',
          drivingSpeed: '120',
          drivingDistance: '12200',
          longitude:'112.941369',
          latitude:'28.236649',
          altitude:'1200',
          postion:'长沙市雨花区'
        },
        

        information:[
          { 
            lable:'报警类型',
            value:'alarmType'
          },
          { 
            lable:'报警等级',
            value:'alarmLevel'
          },
          { 
            lable:'报警开始时间',
            value:'alarmStartTime'
          },
          { 
            lable:'报警结束时间',
            value:'alarmEndTime'
          },
          { 
            lable:'附件数（已接受数/总数）',
            value:'numberOfAttachments',
          },
        ],
        informationData:{
          alarmType:'超速',
          alarmLevel:'二级',
          alarmStartTime:'212-232-22',
          alarmEndTime:'211-122-444',
          numberOfAttachments:'5/5',
        },
        processingSituation:{
            code: 1,
            processName: '李四',
            processMode: '其他',
            processTime: '2019-61-10',
            processTest: '其他',
        },
        submitLoading: false
      }
    }, 
    methods:{
      annex(){
        this.$router.push({path:'/alarm/annex'});
      },
      initAmap (){
        // 构造点标记
        var marker = new AMap.Marker({
          icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
          position: [this.basicInformationData.longitude,this.basicInformationData.latitude]    
        });
        // 构造矢量圆形
        var circle = new AMap.Circle({
            center:[this.basicInformationData.longitude,this.basicInformationData.latitude], // 圆心位置
            radius: 10,  //半径
            strokeColor: "#b1d0f2",  //线颜色
            strokeOpacity: 1,  //线透明度
            strokeWeight: 1,  //线粗细度
            fillColor: "#d7e4f0",  //填充颜色
            fillOpacity: 0.8 //填充透明度
        });
        //初始化地图
        this.map= new AMap.Map('container', {
          center:[this.basicInformationData.longitude,this.basicInformationData.latitude],
          resizeEnable: true,
          zoom: 10,
        });
        this.map.add([marker,circle]);
      },
      newInitAmap() {
        this.$nextTick(() => {
            this.initAmap()
        });
      },
      onSubmit() {
       
      }
    },
    async mounted () {
      // await this.$search(this.table)
    }
  }
</script>
<style lang='sass' scoped>
.clearBox
  .content
    .mapView
      color: #0575E6
      margin-left: 14px 
      cursor: pointer
</style>
